import { useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { gql, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Icon } from '@iconify-icon/react';
import ConfirmButton from '@/storychief/components/ConfirmButton';
import UserThumb from '@/users/components/UserThumb';
import { useEmitEvent } from '../../storychief/hooks';
import { REVIEWS_EVENTS } from '../constants/Constants';
import getFullName from '@/storychief/utils/getFullName';
import useModal from '@/modals/hooks/useModal';
import updateStoryApprovalStatus from '@/stories/actions/updateStoryApprovalStatus';
import getDateObjectWithTimezone from '@/date/getDateObjectWithTimezone';

const propTypes = {
  review: PropTypes.shape({
    id: PropTypes.string,
    requester: PropTypes.shape({
      firstname: PropTypes.string,
    }).isRequired,
    reviewer: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string.isRequired,
    }).isRequired,
    model: PropTypes.shape({
      __typename: PropTypes.string,
      id: PropTypes.string,
      approval_status: PropTypes.string,
    }),
    can: PropTypes.shape({
      delete: PropTypes.bool,
    }),
    reviewer_id: PropTypes.string,
    reviewer_email: PropTypes.string,
    request_message: PropTypes.string,
    approval_message: PropTypes.string,
    created_at: PropTypes.string,
    deleted_at: PropTypes.string,
    accepted_at: PropTypes.string,
    declined_at: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
  isLatest: PropTypes.bool,
};

const defaultProps = {
  isLatest: true,
};

const fragments = {
  model: gql`
    fragment ReviewModel on ReviewInterface {
      __typename
      id
      approval_status
      total_reviews
      total_pending_reviews: total_reviews(filter: { status: PENDING })
      total_accepted_reviews: total_reviews(filter: { status: ACCEPTED })
      total_declined_reviews: total_reviews(filter: { status: DECLINED })
    }
  `,
  review: gql`
    fragment Review on Review {
      __typename
      id
      model_type
      model_id
      reviewer(trashed: WITH) {
        __typename
        id
        firstname
        lastname
        email
        profile_picture
      }
      requester(trashed: WITH) {
        __typename
        id
        firstname
        lastname
        profile_picture
      }
      can {
        delete
      }
      reviewer_id
      reviewer_email
      requester_id
      request_message
      approval_message
      accepted_at
      declined_at
      deleted_at
      created_at
      updated_at
    }
  `,
  user: gql`
    fragment ReviewUser on User {
      __typename
      id
      firstname
      lastname
      email
    }
  `,
};

const DELETE_REVIEW_MUTATION = gql`
  mutation DeleteReview($id: ID!) {
    deleteReview(id: $id) {
      ...Review
      model {
        ...ReviewModel
      }
    }
  }
  ${fragments.review}
  ${fragments.model}
`;

function Review({ type, review, isLatest }) {
  // Mutations
  const [deleteReview, { loading: loadingDeleteReview }] = useMutation(DELETE_REVIEW_MUTATION);

  // Hooks
  const emitUpdateListReviews = useEmitEvent(REVIEWS_EVENTS.updateList);
  const shareModal = useModal('ShareModal');
  const dispatch = useDispatch();

  // Variables
  let date;
  let message;
  switch (type) {
    case 'requested':
      date = review.created_at;
      message = review.request_message || '';
      break;
    case 'declined':
      date = review.declined_at;
      message = review.approval_message || '';
      break;
    case 'approved':
      date = review.accepted_at;
      message = review.approval_message || '';
      break;
    default:
      date = review.created_at;
      message = review.approval_message || '';
  }
  const isProcessing = loadingDeleteReview;

  // States
  const [isMessageTooLong, setIsMessageTooLong] = useState(message.length > 60);

  // Functions
  function handleShowFullMessage() {
    setIsMessageTooLong(false);
  }

  function handleOnDeleteReview() {
    deleteReview({
      variables: {
        id: review.id,
      },
    })
      .then((res) => {
        const deletedReview = res.data?.deleteReview;

        if (deletedReview) {
          dispatch(updateStoryApprovalStatus(deletedReview.model.approval_status));
        }

        return res;
      })
      .then(() => {
        emitUpdateListReviews();
      });
  }

  function toggleShareModal(section) {
    const reviewers = [];

    if (review.reviewer_id) {
      reviewers.push({ id: review.reviewer_id });
    } else if (review.reviewer_email) {
      reviewers.push({ email: review.reviewer_email });
    }

    if (reviewers.length === 0) {
      return;
    }

    shareModal.toggle({
      props: {
        modelType: review.model_type,
        modelId: review.model_id,
        section,
        selectedReviewers: reviewers,
      },
    });
  }

  // Rendering
  return (
    <div className="row approval-list-item" key={review.id}>
      <div className="col-xs-10">
        <div className="d-flex">
          <UserThumb user={review.reviewer} />
          <div className="small gutter-left-2">
            {review.reviewer.firstname || review.reviewer.lastname ? (
              <strong>{getFullName(review.reviewer)}</strong>
            ) : (
              <strong>{review.reviewer.email}</strong>
            )}
            <br />
            <span className="text-muted">
              <span
                className="text-capitalize"
                data-testid="open-review-approve-message"
              >{`${type} `}</span>

              {getDateObjectWithTimezone(date).toRelative()}

              {type === 'requested' && (
                <span>
                  {` by ${review.requester ? review.requester.firstname : 'Name'} `}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="help-description">
                        {review.request_message || 'No message'}
                      </Tooltip>
                    }
                  >
                    <Icon
                      icon="fa:envelope-o"
                      className="text-muted"
                      inline
                      width="14"
                      height="14"
                    />
                  </OverlayTrigger>
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="col-xs-2">
        {isProcessing && (
          <span className="animated-spin d-inline-block pull-right">
            <span className="icon-spin1" />
          </span>
        )}
        {review.can && review.can.delete && !isProcessing && isLatest && (
          <div className="dropdown d-inline-block pull-right">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              type="button"
              className="btn btn-chromeless"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="flex">
                <Icon icon="bi:three-dots" width="16" />
              </span>
            </a>
            <ul className="dropdown-menu">
              <li>
                <button
                  type="button"
                  className="btn btn-chromeless"
                  onClick={() => toggleShareModal('review')}
                >
                  Resend
                </button>
              </li>
              <li>
                <ConfirmButton
                  className="btn btn-chromeless"
                  expectedInput="DELETE THREAD"
                  description="The full review thread will be deleted"
                  action={handleOnDeleteReview}
                >
                  Delete
                </ConfirmButton>
              </li>
            </ul>
          </div>
        )}
      </div>

      {type !== 'requested' && message && (
        <div className="col-xs-12 space-top-1 small gutter-left-9">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div
            className="well well-sm well-chat m-0"
            role={isMessageTooLong ? 'button' : ''}
            onClick={handleShowFullMessage}
          >
            {isMessageTooLong ? (
              <span>
                {`${message.substring(0, 60)}...`}
                <em className="icon-angle-down space-top-1 pull-right" />
              </span>
            ) : (
              <span>{message}</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

Review.propTypes = propTypes;
Review.defaultProps = defaultProps;
Review.fragments = fragments;

export default Review;
